import '../App.css';
import React, { useEffect } from 'react';
import { GetAllShapes } from '../lib/ApiHandler/ShapeApi';
import { GetAllMarkers } from '../lib/ApiHandler/MarkerApi';
import { useDispatch } from 'react-redux'
import { setPolygons } from '../state/slices/PolygonSlice';
import { setMarkers } from '../state/slices/MarkerSlice';
import { silentLogin } from '../lib/Auth/AuthHandler';
import { useNavigate } from 'react-router'
import { HashLoader } from 'react-spinners';
import useAuth from '../lib/Hooks/useAuth';

function Authenticated({children}) {
    const dispatch = useDispatch()
    const { jwtToken, setJwt } = useAuth()
    const navigate = useNavigate();

    useEffect(() => {
        if (jwtToken === null) {
            
            /* Try a silent login */
            silentLogin((userCredentials) => {
                if(userCredentials === null || userCredentials === "Missing Role"){
                    navigate("/login");
                } else {
                    setJwt(userCredentials)
                    /* It was successfull, set the JWT Token and be happy */
                    GetAllShapes(userCredentials.jwtToken, (resultPolygons) => {
                        dispatch(setPolygons(resultPolygons))                     
                    })
                    GetAllMarkers(userCredentials.jwtToken, (resultMarkers) => {
                        dispatch(setMarkers(resultMarkers))
                    })
                }
            })
        } else {
            GetAllShapes(jwtToken, (resultPolygons) => {
                dispatch(setPolygons(resultPolygons))
            })
            GetAllMarkers(jwtToken, (resultMarkers) => {
                dispatch(setMarkers(resultMarkers))
            })
        }
        
        const getNewJwt = setInterval(() => {
            silentLogin((userCredentials) => {
                setJwt(userCredentials)
            })
        }, 1800000);

        return (() => {
            clearInterval(getNewJwt)
        });
    }, [dispatch, jwtToken, navigate, setJwt])

    if(jwtToken === null){
        return (
            <div className='h-dvh w-dvw flex flex-col justify-center'>
                <HashLoader className='mx-auto' />
            </div>
        );
    }
    
    return (
        <>
        {children && React.cloneElement(children, { jwtToken: jwtToken })}
        </>
    );

}


export default Authenticated;
