import MarkersTable from "./MarkersTable/MarkersTable";
import MainLayout from "../../Layouts/MainLayout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetSasToken } from "../../lib/ApiHandler/BlobApi";
import { setSasToken } from "../../state/slices/SasTokenSlice";


const Markers = ({jwtToken}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        
        /* Setting the sas token */
        GetSasToken(jwtToken, (sasToken) => {
            dispatch(setSasToken(sasToken))
        })

        /* Getting another sastoken before it expires */
        const interval = setInterval(() => {
            GetSasToken(jwtToken, (sasToken) => {
                dispatch(setSasToken(sasToken))
            })
        }, 200000);

        return (() => {
            clearInterval(interval)
        });
    }, [dispatch, jwtToken])

    return (
        <MainLayout>
            <div className="container p-12 max-h-dvh overflow-y-scroll">
                <MarkersTable></MarkersTable>
            </div>
        </MainLayout>
    );
}

export default Markers