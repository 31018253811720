import { useState } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import {
  CreateMarkerFromLayer,
} from "../../../lib/ShapeHandler";
import CreateMarkerModal from './CreateMarkerModal'
import { CreateMarker } from "../../../lib/ApiHandler/MarkerApi";
import { useDispatch } from 'react-redux'
import { addMarker } from "../../../state/slices/MarkerSlice";
import useAuth from "../../../lib/Hooks/useAuth";

const MarkerEditor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newMarkerLayer, setNewMarkerLayer] = useState(null);
  const dispatch = useDispatch();
  const { jwtToken } = useAuth();

  const handleModalSave = (title, description, imageNames) => {
    if (newMarkerLayer) {
      const newMarker = CreateMarkerFromLayer(newMarkerLayer);
      newMarker.name = title; 
      newMarker.description = description;
      newMarker.images.push(...imageNames);      
    
      CreateMarker(newMarker, jwtToken, (mongoDbMarker) => {
        dispatch(addMarker(mongoDbMarker))
      });
    }
  };

  const created = (layer) => {
    if (layer.layer._latlng) {
      setNewMarkerLayer(layer); // Store the new marker layer
      setIsModalOpen(true); // Open the modal to get user input
    }
  }

  return (
    <FeatureGroup>
      <EditControl
        position="bottomright"
        draw={{
          circle: false,
          circlemarker: false,
          polyline: false,
          rectangle: false,
          polygon: false,
          marker: false
        }}
        edit={{
          remove: false,
          edit: false
        }}
        onCreated={created}
      />
      <CreateMarkerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleModalSave}
      />
    </FeatureGroup>
  );
};

export default MarkerEditor;
