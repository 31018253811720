import React from 'react';
import { FiX, FiAlertCircle } from 'react-icons/fi'; // Importing icons from react-icons
import ReactDOM from 'react-dom'
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import DangerButton from '../../components/Buttons/DangerButton';

// CloseButton Component
const CloseButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      onClick={onClick}
    >
      <FiX className="w-3 h-3" />
      <span className="sr-only">Close modal</span>
    </button>
  );
};

// ModalContent Component
const ModalContent = ({ children, onAccept, onDeny }) => {
  return (
    <div className="p-4 md:p-5 text-center">
      <FiAlertCircle className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" />
      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
        {children}
      </h3>
      <div className='space-x-4'>
        <DangerButton onClick={onAccept}>Ja, jag är säker</DangerButton>
        <SecondaryButton onClick={onDeny}>Nej, avbryt</SecondaryButton>
      </div>
    </div>
  );
};

// ModalWrapper Component
const ModalWrapper = ({ children }) => {
  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      style={{ zIndex: 200000000000 }}
      className="fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-full flex bg-black bg-opacity-50"
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {children}
        </div>
      </div>
    </div>
  );
};

// ApproveDenyModal Component (Main Modal)
const ApproveDenyModal = ({ onAccept, onDeny, children }) => {
  return (
    ReactDOM.createPortal(
      <ModalWrapper>
      <CloseButton onClick={onDeny} />
      <ModalContent onAccept={onAccept} onDeny={onDeny}>
        {children}
      </ModalContent>
    </ModalWrapper>,
    document.body
    )
   
  );
};

export default ApproveDenyModal;