import { removePolygon, updatePolygon } from "../../state/slices/PolygonSlice";
import { DeletePolygon, UpdatePolygon } from "../ApiHandler/ShapeApi";
import { useEffect, useRef, useState } from "react";
import useAuth from "./useAuth";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedPolygon } from "../../state/slices/SelectedPolygonSlice";


const useSelectedPolygonOverlay = () => {
    const dispatch = useDispatch();
    const { jwtToken } = useAuth();

    /* Select state objects */
    const selectedPolygon = useSelector(state => state.selectedPolygon.selected);
    const displaySelectedPolygon = useSelector(state => state.selectedPolygon.dispalyEdit);

    /* Declare sattes  */
    const [color, setColor] = useState(selectedPolygon.fillColor)
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(selectedPolygon? true: false)
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    /* Refs */
    const polygonName = useRef();
    const polygonDescription = useRef();

    const saveSelectedPolygonChanges = () => {
        const newPolygon = {...selectedPolygon}
        newPolygon.name = polygonName.current.value;
        newPolygon.description = polygonDescription.current.value;
        newPolygon.fillColor = color !== undefined ? color: selectedPolygon.fillColor;
        
        UpdatePolygon(newPolygon, jwtToken, (updatedPolygon) => {
            dispatch(updatePolygon(updatedPolygon))
        });
    }

    const removePolygonApprove = () => {
        DeletePolygon(selectedPolygon, jwtToken, () => {
            dispatch(removePolygon(selectedPolygon))
            dispatch(setSelectedPolygon(null))
            setIsRemoveModalOpen(false)
        });
    }

    useEffect(() => {
        setColor(selectedPolygon.fillColor)
    }, [selectedPolygon.fillColor])
    
    return {color, setColor, isEditDrawerOpen, setIsEditDrawerOpen, selectedPolygon, polygonName, polygonDescription, saveSelectedPolygonChanges, displaySelectedPolygon, isRemoveModalOpen, setIsRemoveModalOpen, removePolygonApprove};

}

export default useSelectedPolygonOverlay