import { useDispatch } from "react-redux";
import { setSelectedPolygon } from "../../../state/slices/SelectedPolygonSlice";
import { IoCloseSharp } from "react-icons/io5";

const SelectedPolygonUserVersion = ({selectedPolygon}) => {
    const dispatch = useDispatch();

    return (
      <div className="w-72 h-auto bg-white rounded-md p-2 bottom-16 sm:bottom-0 right-1 fixed flex flex-col" style={{zIndex: 5000}}>
      <div className="flex flex-row justify-between">
        <p className="font-bold">{selectedPolygon.name}</p>
        <div onClick={() => {
              dispatch(setSelectedPolygon(null))
            }}><IoCloseSharp className="text-xl text-gray-600 hover:text-red-700"/></div>
      </div>
      <div>
        <p>{selectedPolygon.description}</p>
      </div>
    </div>
    );
}

export default SelectedPolygonUserVersion