import { GetInitials } from "../../lib/Auth/AuthUtil";
import useAuth from "../../lib/Hooks/useAuth";
import { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaMap } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MainSidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const { name, email } = useAuth();
  const markers = useSelector(state => state.markers);

  const triggerExapndedNav = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
          className={
            "bg-slate-800 md:w-64 transition-all " + (expanded ? "w-64" : "w-16")
          }
        >
          <aside
            id="default-sidebar"
            className={
              "z-40 h-dvh transition-transform flex flex-col justify-between bg-gray-50 " +
              (expanded ? "w-64" : "w-16 md:w-64")
            }
            aria-label="Sidebar"
          >
            <button
              onClick={triggerExapndedNav}
              type="button"
              className="block items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>

            <div className="h-full px-3 overflow-y-auto mt-3">
              <img
                alt="sca logo"
                src="https://www.sca.com/imagevault/publishedmedia/w729rd4d7egty7at5nb8/1.0_sid_9_1.png"
                className="h-16 m-auto hidden md:block"
              />
              <NavLinkSection>
                <NavLink link={"/map"} expanded={expanded} icon={FaMap}>
                  Karta
                </NavLink>
                <NavLink
                  link={"/markers"}
                  expanded={expanded}
                  icon={FaMapMarkerAlt}
                  label={markers.filter(x => x.deleted !== true).length}
                >
                  Markeringar
                </NavLink>
              </NavLinkSection>
            </div>

            <div class="flex items-center space-x-4 p-4 rounded-md shadow-md">
              <div class="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center">
                <span class="text-gray-500 text-sm font-medium">{GetInitials(name)}</span>
              </div>
              <div>
                <h2 class="text-sm font-semibold text-gray-800">{name}</h2>
                <p class="text-xs text-gray-500">{email}</p>
              </div>
            </div>

          </aside>
        </div>
    </div>
  );
};

const NavLinkSection = ({ children }) => {
  return <ul className="space-y-2 font-medium">{children}</ul>;
};

const NavLink = ({ children, expanded, icon, label, link }) => {
  return (
    <li>
      <Link
        to={link}
        className="flex flex-row items-center p-2 text-gray-800 rounded-lg hover:bg-gray-100 group"
      >
        <span className="text-2xl md-text-sm">{icon()}</span>
        <span
          className={
            "flex-1 ms-3 whitespace-nowrap md:block " +
            (expanded ? "block" : "hidden")
          }
        >
          {children}
        </span>
        <span className="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
          {label}
        </span>
      </Link>
    </li>
  );
};

export default MainSidebar;
