import { handleSignIn } from '../lib/Auth/AuthHandler';
import { useNavigate } from 'react-router-dom'
import { NotifyError } from "../lib/Notify/notify";
import useAuth from "../lib/Hooks/useAuth";

const Login = () => {
    const navigate = useNavigate();
    const { setJwt } = useAuth();

    const logInTrigger = async () => {
        await handleSignIn((userCredentials) => {
            if(userCredentials === "Missing Role"){
                NotifyError("Du har inte access till denna applikation.")
                return;
            } else {
                setJwt(userCredentials);
            }
        })
        navigate('/map')
    }
    
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-10 w-auto" src="https://www.svgrepo.com/show/301692/login.svg" alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Bollstasågen - Interaktiv karta
            </h2>
        </div>
    
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <button onClick={logInTrigger} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  Logga in med SSO
                </button>
            </div>
        </div>
    </div>
    );
}

export default Login