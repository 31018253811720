import { useMap } from "react-leaflet";
import "@geoman-io/leaflet-geoman-free";
import { useCallback, useEffect } from "react";
import { GenerateShape } from "../../lib/ShapeHandler";
import { useDispatch, useSelector } from "react-redux";
import { CreatePolygon, DeletePolygon } from "../../lib/ApiHandler/ShapeApi";
import { addPolygon, removePolygon } from "../../state/slices/PolygonSlice";
import useAuth from "../../lib/Hooks/useAuth";
import L from 'leaflet'
import "../../lib/Map/CustomControls/crossDrawer";

const Geoman = () => {
    const map = useMap();
    const currentSelectd = useSelector(state => state.selectedPolygon.selected);
    const { jwtToken } = useAuth(); 
    const dispatch = useDispatch();

    const duplicateSelected = useCallback(() => {
        CreatePolygon({
            ...currentSelectd,
            name: currentSelectd.name + ' copy'
        }, jwtToken, (mongoResponse) => {
            dispatch(addPolygon(mongoResponse))
        });
    }, [currentSelectd, dispatch, jwtToken])


    const handleKeyDown = useCallback((event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'u') {
            if(currentSelectd){
                duplicateSelected();
            }
            event.preventDefault();
        }
    }, [currentSelectd, duplicateSelected])

    useEffect(() => {
        // Ensure the map instance is available
        if (!map) return;

        map.pm.addControls({
            position: 'bottomleft',
            drawCircle: false,
            drawCircleMarker: false,
            drawMarker: false,
            drawText: false,
            cutPolygon: false,
            removalMode: false
        });

        window.addEventListener("keydown", handleKeyDown)

        // Initialize the CrossHelper addon
        const crossHelper = new L.PM.Draw.CrossHelper(map, {
            lineLength: 0.5, // Adjust the length of the cross
            lineStyle: {
                color: "green",
                dashArray: "5,5",
            },
        });

        crossHelper.addListeners();

        const onCreate = (event) => {
            if(event.shape === "Line")
                return;

            /* Remove the leaflet placeholder. A new SCA Polygon is created. */
            map.removeLayer(event.layer);

            const shape = GenerateShape(event, currentSelectd);
            CreatePolygon(shape, jwtToken, (mongoResponse) => {
                dispatch(addPolygon(mongoResponse))
            });
        };

        const onRemove = (event) => {
            DeletePolygon(event.layer.options, jwtToken, () => {
                dispatch(removePolygon({id: event.layer.options.id}))
            });
        }

        map.on('pm:create', onCreate);
        map.on('pm:remove', onRemove);

        // Clean up event listeners on unmount
        return () => {
            map.off('pm:create', onCreate);
            map.on('pm:remove', onRemove);
            window.removeEventListener("keydown", handleKeyDown)
            crossHelper.removeListeners();

        };
    }, [map, currentSelectd, dispatch, jwtToken, handleKeyDown]);

    return null; // This component doesn't render anything
};

export default Geoman;
