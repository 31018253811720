import { Banner } from "flowbite-react";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { MdAnnouncement } from "react-icons/md";
import { GetSasToken, UploadBlobsAsync } from "../../../lib/ApiHandler/BlobApi";
import { useSelector } from "react-redux";
import { FaFileAlt, FaFileImage } from "react-icons/fa";
import { IoTrash } from "react-icons/io5";
import LoadingOverlay from "../../RootElements/LoadingOverlay";
import resizeImage from "../../../lib/ImageResize";
import useAuth from "../../../lib/Hooks/useAuth";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";

const CreateMarkerModal = ({ isOpen, onClose, onSave }) => {
  if (!isOpen) return null;

  const handleSave = (title, description, imageName) => {
    onSave(title, description, imageName);
    onClose();
  };

  return ReactDOM.createPortal(<MarkerModal handleSave={handleSave} onClose={onClose} />, document.body);
};


const MarkerModal = ({handleSave, onClose}) => {
    const markerTitle = useRef();
    const markerDescription = useRef();
    const markerFile = useRef();
    const [createMarkerState, setCreateMarkerState] = useState(true);
    const [imgCount, setImgCount] = useState(0);
    const { jwtToken } = useAuth();
    const [images, setImages] = useState([]);
    const sasToken = useSelector(state => state.sasToken);
    const [isLoading, setIsLoading] = useState();

    const onMarkerCreate = () => {
        handleSave(markerTitle.current.value, markerDescription.current.value, images);
    }

    const onChange = (event) => {
      setCreateMarkerState(false)
      setImgCount(event.target.files.length);
      setIsLoading(true);
      if(event.target.files){
        GetSasToken(jwtToken, async (sastoken) => {
          let blobs = []
          for (let index = 0; index < event.target.files.length; index++) {
            blobs.push(resizeImage(event.target.files[index]))
          }
          UploadBlobsAsync(sastoken, blobs, (uploadedBlobNames) => {
            setImages((prev) => [...prev, ...uploadedBlobNames])
            setIsLoading(false)
          })          
        }) 
      }
    }

    if(imgCount === images.length && createMarkerState === false){
      setCreateMarkerState(true)
    }

  return (
    <div
      tabIndex="-1"
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] h-screen bg-black bg-opacity-50"
      style={{ zIndex: 3000 }}>
      <div className="relative p-4 w-full max-w-2xl max-h-full mx-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="p-4 md:p-5 space-y-4">
            <Banner>
              <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                <div className="mx-auto flex items-center">
                  <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                    <MdAnnouncement className="mr-4 h-4 w-4" />
                    <span className="[&_p]:inline">
                      Tänk på filstorleken när du laddar upp bilder.
                    </span>
                  </p>
                </div>
              </div>
            </Banner>

            <div className="my-6">
                <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Marker titel</label>
                <input ref={markerTitle} placeholder="Marker titel..." type="text" id="title" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>
            <div>
                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beskrivning</label>
                <textarea ref={markerDescription} id="description" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Beskrivning..."></textarea>
            </div>
            <div>

              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Ladda upp filer</label>
              <input accept="image/*" multiple={true} ref={markerFile} onChange={onChange} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" />

              <div className="flex flex-col mt-2">
                {images.map(image => {
                  return <UploadedFile imageName={image} sasToken={sasToken} removeUploadedFile={(imageName) => {
                    setImages(images.filter(x => x !== imageName))
                  }}/>
                })}
              </div>
            </div>
          </div>

          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 space-x-4">
            <PrimaryButton onClick={onMarkerCreate} disabled={!createMarkerState}>Skapa marker</PrimaryButton>
            <SecondaryButton onClick={onClose}>Stäng</SecondaryButton>
          </div>
        </div>
      </div>
      {isLoading? <LoadingOverlay />: null}
    </div>
  );
};

const UploadedFile = ({imageName, removeUploadedFile}) => {
  const fileExtension = imageName.split('.').pop().toLowerCase();
  return (
    <div className="border border-stone-300 my-1 p-1 bg-stone-50 rounded-md shadow flex flex-row justify-between">
      <div className="flex flex-row">
        <span className="my-auto">
          {fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg'? <FaFileImage /> : <FaFileAlt /> }
        </span>
        <span className="my-auto ml-2 text-stone-800">
          {imageName}
        </span>
      </div>
      <div>
        <IoTrash className="text-red-400 text-xl my-auto hover:text-red-600" onClick={() => {
          removeUploadedFile(imageName)
        }}/>
      </div>
    </div>
  );
}

export default CreateMarkerModal;
