import { useSelector } from "react-redux";
import useAuth from "../../../lib/Hooks/useAuth";
import SelectedPolygonUserVersion from "./UserVersion";
import SelectedPolygonAdminVersion from "./AdminVersion";


const SelectedShapeOverlay = () => {
    
    const { isAdmin } = useAuth();
    const selectedPolygon = useSelector(state => state.selectedPolygon.selected);

    if(!selectedPolygon){
      return null;
    }

    /* Render User version (less information) */
    if(!isAdmin()){
      return (<SelectedPolygonUserVersion selectedPolygon={selectedPolygon}/>);
    }

    return (<SelectedPolygonAdminVersion />);
  }



export default SelectedShapeOverlay