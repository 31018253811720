import { useCallback, useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import ReactDOM from 'react-dom'
import { HiViewColumns } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { GenerateShape } from "../../../lib/ShapeHandler";
import { CreatePolygon } from "../../../lib/ApiHandler/ShapeApi";
import useAuth from "../../../lib/Hooks/useAuth";
import { addPolygon } from "../../../state/slices/PolygonSlice";

/* Control triggers the creation of markers without entering the edit mode */
const SubPolygonControl = () => {
    const map = useMap();
    const selectedPolygon = useSelector(state => state.selectedPolygon.selected);
    const { jwtToken } = useAuth()
    const dispatch = useDispatch();

    const onCreate = useCallback((e) => {
        const rows = prompt("Columns: ");
        const columns = prompt("Rows: ");

        /* Draw polygon on the map, then continue process the code. */
        var polygon = GenerateShape(e, selectedPolygon);
        const boundary = polygon.position;
        const generatedPolygons = generatePolygonsInBoundary(boundary, rows, columns, 1,1);

        generatedPolygons.forEach(element => {

            const newPoly = {
                name: "matrix-poly", // Fallback to default name if user cancels
                position: element,
                fillColor: "blue",
                index: selectedPolygon? 2: 1,
                parentId: selectedPolygon? selectedPolygon.id: null,
                description: "",
                polygonType: "Polygon"
            };
            console.log(newPoly);

            CreatePolygon(newPoly, jwtToken, (mongoResponse) => {
                dispatch(addPolygon(mongoResponse))
            });
        });
        
    }, [jwtToken, selectedPolygon, dispatch])
    
    useEffect(() => {
        var position = "bottomleft";
        const customControl = L.control({ position: position });

        /* When clicked, the user should be prompted */
        const onControlClick = (map) => {

            map.pm.enableDraw('Polygon', {
                tooltips: true,
                allowSelfIntersection: false,
                templineStyle: {
                    color: 'blue',
                },
                hintlineStyle: {
                    color: 'blue',
                    dashArray: [5, 5],
                },
                pathOptions: {
                    color: 'blue',
                    fillColor: 'blue',
                    fillOpacity: 0.4,
                },
            });

            map.on("pm:create", onCreate)
        };

        // Define the onAdd function for the control
        customControl.onAdd = () => {
            const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
            const button = L.DomUtil.create('a', 'leaflet-control-button', container);
            
            // Render the React icon into the button
            const iconElement = document.createElement('div');
            iconElement.className = 'leaflet-control-icon h-full flex flex-col justify-center';
            const root = ReactDOM.createRoot(iconElement);
            root.render(<HiViewColumns  className="mx-auto text-xl text-gray-700"/>);
            button.appendChild(iconElement);

            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', () => {
                onControlClick(map);
            });

            container.title = "Add Marker";
            return container;
        };

        customControl.addTo(map);

        // Cleanup on unmount
        return () => {
            customControl.remove();
            map.off("pm:create", onCreate)
        };
    }, [map, selectedPolygon, onCreate]);
};

function generatePolygonsInBoundary(boundaryPoints, rows, columns, spaceX, spaceY) {
    // Helper to find the bottom-left point
    function findBottomLeft(points) {
        return points.reduce((bottomLeft, point) => {
            if (
                point[0] < bottomLeft[0] ||
                (point[0] === bottomLeft[0] && point[1] < bottomLeft[1])
            ) {
                return point;
            }
            return bottomLeft;
        }, points[0]);
    }

    // Reorder boundary points to start from bottom-left and go clockwise
    function reorderBoundary(points) {
        const pointsCopy = [...points];
        const bottomLeft = findBottomLeft(pointsCopy);
        pointsCopy.sort(
            (a, b) =>
                Math.atan2(a[1] - bottomLeft[1], a[0] - bottomLeft[0]) -
                Math.atan2(b[1] - bottomLeft[1], b[0] - bottomLeft[0])
        );
        return pointsCopy;
    }

    boundaryPoints = reorderBoundary(boundaryPoints);

    const [point1, point2, point3, point4] = boundaryPoints; // SW, SE, NE, NW (clockwise)

    // Calculate total width and height in meters
    const boundaryWidth = L.latLng(point1).distanceTo(L.latLng(point2)); // Bottom edge
    const boundaryHeight = L.latLng(point1).distanceTo(L.latLng(point4)); // Left edge

    // Calculate polygon dimensions
    const polygonWidth = (boundaryWidth - (columns - 1) * spaceX) / columns;
    const polygonHeight = (boundaryHeight - (rows - 1) * spaceY) / rows;

    if (polygonWidth <= 0 || polygonHeight <= 0) {
        console.error("Spacing or dimensions are too large for the given boundary.");
        return [];
    }

    // Calculate unit vectors for x and y directions
    const xUnitVector = [
        (point2[0] - point1[0]) / boundaryWidth,
        (point2[1] - point1[1]) / boundaryWidth,
    ];
    const yUnitVector = [
        (point3[0] - point1[0]) / boundaryHeight,
        (point3[1] - point1[1]) / boundaryHeight,
    ];

    // Generate polygons
    const polygons = [];
    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
            // Calculate the top-left corner of the polygon
            const topLeft = [
                point1[0] +
                    col * (polygonWidth + spaceX) * xUnitVector[0] +
                    row * (polygonHeight + spaceY) * yUnitVector[0],
                point1[1] +
                    col * (polygonWidth + spaceX) * xUnitVector[1] +
                    row * (polygonHeight + spaceY) * yUnitVector[1],
            ];

            // Calculate the remaining corners based on the top-left
            const topRight = [
                topLeft[0] + polygonWidth * xUnitVector[0],
                topLeft[1] + polygonWidth * xUnitVector[1],
            ];
            const bottomLeft = [
                topLeft[0] + polygonHeight * yUnitVector[0],
                topLeft[1] + polygonHeight * yUnitVector[1],
            ];
            const bottomRight = [
                bottomLeft[0] + polygonWidth * xUnitVector[0],
                bottomLeft[1] + polygonWidth * xUnitVector[1],
            ];

            // Add the polygon to the list
            polygons.push([topLeft, topRight, bottomRight, bottomLeft]);
        }
    }

    return polygons;
}

export default SubPolygonControl;
