import { NotifyError, NotifySuccess } from "../Notify/notify";

/* 
    For user experience reasons, a cached version of the polygons is first returned. 
    Once the request returns from the BE, this cached version is updated.
*/  
export const GetAllShapes = (jwtToken, callback) => {

    /* Return cached version of the result if exists */
    var polygons = localStorage.getItem("polygons");

    if(polygons !== null && polygons !== undefined)
        callback(JSON.parse(polygons))
    else 
        callback([])
    
    HttpRequest(process.env.REACT_APP_API_BASE_URL + '/shapes', jwtToken, "GET")
    .then(result => {
        localStorage.setItem("polygons", JSON.stringify(result))
        callback(result);
    })
    .catch(error => {
        NotifyError(error);
        console.log(error);
        callback(polygons);
    })
}

export const CreatePolygon = (polygon, jwtToken, callback) => {
    HttpRequest(process.env.REACT_APP_API_BASE_URL + '/shapes', jwtToken, "POST", {
        name: polygon.name,
        description: polygon.description,
        fillColor: polygon.fillColor,
        index: polygon.index,
        parentId: polygon.parentId,
        position: polygon.position,
        polygonType: polygon.polygonType? polygon.polygonType: "Polygon"
    })
    .then(result => {
        NotifySuccess("Yta skapad!");
        callback(result);
    })
    .catch(error => {
        NotifyError(error);
        callback(error);
    })
}

export const UpdatePolygon = (polygon, jwtToken, callback) => {
    HttpRequest(process.env.REACT_APP_API_BASE_URL + '/shapes', jwtToken, "PUT", {
        name: polygon.name,
        description: polygon.description,
        id: polygon.id,
        fillColor: polygon.fillColor,
        index: polygon.index,
        parentId: polygon.parentId,
        position: polygon.position,
        polygonType: polygon.polygonType? polygon.polygonType: "Polygon"
    })
    .then(result => {
        NotifySuccess("Yta Uppdaterad!");
        callback(result);
    })
    .catch(error => {
        NotifyError(error);
        callback(error);
    })
}

export const DeletePolygon = (polygon, jwtToken, callback) => {
    HttpRequest(process.env.REACT_APP_API_BASE_URL + '/shapes/'+polygon.id, jwtToken, "DELETE")
    .then(result => {
        NotifySuccess("Yta borttagen!");
        callback(result);
    })
    .catch(error => {
        NotifyError(error);
        callback(error);
    })
} 

const HttpRequest = (url, jwtToken, method, body = null) => {
    var options = {
        method: method,
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "Application/json",
        }
    }
    if(body !== null)
        options.body = JSON.stringify(body);

    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(async response => {
            if(response.status >= 299){
                reject(await response.text())
            } else {
                resolve(await response.json())
            }
        })
        .catch(async error => {
            reject("Internal Server error")
        });
    })
}