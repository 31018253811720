import L from 'leaflet'

L.PM.Draw.CrossHelper = L.Class.extend({
    initialize(map, options = {}) {
        this.map = map;
        this.options = {
            lineLength: 0.5, // Default line length in km
            lineStyle: {
                color: "blue",
                dashArray: "5,5",
            },
            ...options,
        };
        this.currentDrawings = [];
        this.supportLinesLayer = L.layerGroup().addTo(this.map); // Store all support lines
    },

    calculateBearing(point1, point2) {
        const lat1 = (point1.lat * Math.PI) / 180;
        const lat2 = (point2.lat * Math.PI) / 180;
        const dLng = ((point2.lng - point1.lng) * Math.PI) / 180;

        const y = Math.sin(dLng) * Math.cos(lat2);
        const x =
            Math.cos(lat1) * Math.sin(lat2) -
            Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLng);

        const bearing = (Math.atan2(y, x) * 180) / Math.PI;
        return (bearing + 360) % 360; // Normalize to 0-360
    },

    calculateDestinationPoint(lat, lng, distance, bearing) {
        const R = 6371; // Earth radius in km
        const angularDistance = distance / R; // Convert distance to angular distance
        const bearingRad = (bearing * Math.PI) / 180;

        const latRad = (lat * Math.PI) / 180;
        const lngRad = (lng * Math.PI) / 180;

        const newLat = Math.asin(
            Math.sin(latRad) * Math.cos(angularDistance) +
                Math.cos(latRad) * Math.sin(angularDistance) * Math.cos(bearingRad)
        );

        const newLng =
            lngRad +
            Math.atan2(
                Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(latRad),
                Math.cos(angularDistance) - Math.sin(latRad) * Math.sin(newLat)
            );

        return {
            lat: (newLat * 180) / Math.PI,
            lng: (newLng * 180) / Math.PI,
        };
    },

    drawCross(latlng, referencePoint) {
        if (referencePoint) {
            const bearing = this.calculateBearing(referencePoint, latlng);

            const perpendicularBearing1 = (bearing + 90) % 360;
            const perpendicularBearing2 = (bearing - 90 + 360) % 360;

            const lineLength = this.options.lineLength;

            const line1Start = this.calculateDestinationPoint(
                latlng.lat,
                latlng.lng,
                lineLength,
                bearing
            );
            const line1End = this.calculateDestinationPoint(
                latlng.lat,
                latlng.lng,
                lineLength,
                (bearing + 180) % 360
            );

            const line2Start = this.calculateDestinationPoint(
                latlng.lat,
                latlng.lng,
                lineLength,
                perpendicularBearing1
            );
            const line2End = this.calculateDestinationPoint(
                latlng.lat,
                latlng.lng,
                lineLength,
                perpendicularBearing2
            );

            // Add lines to the persistent support lines layer
            this.supportLinesLayer.addLayer(
                L.polyline([line1Start, line1End], this.options.lineStyle)
            );
            this.supportLinesLayer.addLayer(
                L.polyline([line2Start, line2End], this.options.lineStyle)
            );
        }
    },

    addListeners() {
        this.map.on("pm:drawstart", ({ workingLayer }) => {
            this.currentDrawings = [];
            this.supportLinesLayer.clearLayers(); // Clear any previous support lines

            workingLayer.on("pm:vertexadded", (e) => {
                const latlng = e.latlng;
                this.currentDrawings.push(latlng);

                if (this.currentDrawings.length === 1) {
                    // For the first point, wait for the second point to define the cross
                    workingLayer.on("pm:vertexadded", () => {
                        if (this.currentDrawings.length >= 2) {
                            const secondPoint = this.currentDrawings[1];
                            this.drawCross(latlng, secondPoint); // Reference second point
                        }
                    });
                } else {
                    // Draw cross for the subsequent points
                    const referencePoint = this.currentDrawings[this.currentDrawings.length - 2];
                    this.drawCross(latlng, referencePoint);
                }
            });
        });

        this.map.on("pm:drawend", () => {
            this.supportLinesLayer.clearLayers(); // Remove all support lines
            this.currentDrawings = [];
        });
    },

    removeListeners() {
        this.map.off("pm:drawstart");
        this.map.off("pm:drawend");
    },
});

