import { useDispatch, useSelector } from "react-redux";
import { APP_ADMIN_ROLE } from "../Constants";
import { setJwtToken } from "../../state/slices/AuthSlice";


const useAuth = () => {
    const roles = useSelector(state => state.auth.roles);
    const jwtToken = useSelector(state => state.auth.jwtToken);
    const email = useSelector(state => state.auth.username);
    const name = useSelector(state => state.auth.name);
    const dispatch = useDispatch();
    const isAdmin = () => {
        return roles.includes(APP_ADMIN_ROLE)
    }
    const setJwt = (token) => {
        dispatch(setJwtToken(token))
    }

    return { jwtToken, email, name, isAdmin, setJwt }
}

export default useAuth